import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ChevronDown, Menu, X } from "lucide-react";
import "../styles/Navbar.scss";

import logo from "../assets/logo-Desktop.png";
import contactIcon from "../assets/contact.svg";

const Navbar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleisOpen = () => {
    setIsOpen(!isOpen);
  };

  const isHome = location.pathname === "/";

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        // You can adjust this value
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div
        className={`navbar ${isHome ? "home-edition" : ""} ${
          isSticky ? "sticky" : ""
        }`}
      >
        <div className="navbar_container">
          <div className={`navbar_menu ${isOpen ? "active" : ""}`}>
            <Link to="/" className="navbar_link">
              Anasayfa
            </Link>
            <div className="navbar_dropdown">
              <button className="navbar_link" onClick={handleDropdown}>
                Kurumsal
                <ChevronDown className="navbar_icon" />
              </button>
              {dropdown && (
                <div className="dropdown_content">
                  <Link className="dropdown_link" to="/about">
                    Hakkımızda
                  </Link>
                  <Link className="dropdown_link" to="/sss">
                    Sıkça Sorulan Sorular
                  </Link>
                </div>
              )}
            </div>
            <Link to="/products" className="navbar_link">
              Ürünlerimiz
            </Link>
            <Link to="/pnp" className="navbar_link">
              Gizlilik Politikası
            </Link>
            <Link to="/calculator" className="navbar_link">
              Kazanç Hesapla
            </Link>

            <div className="navbar_contact">
              <Link to="/contact" className="navbar_button">
                Bize Ulaşın
                <img src={contactIcon} alt="contact us" />
              </Link>
            </div>
          </div>
          <button className="navbar_toggle" onClick={handleisOpen}>
            {isOpen ? <X /> : <Menu />}
          </button>
          <div className="navbar_logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
