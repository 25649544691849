import remoteImg from "./assets/products/Remote(Kumanda).png";
import monitorImg from "./assets/products/monitor.png";
import sensorImg from "./assets/products/Harici-sensor.png";
import internalImg from "./assets/products/internal.png";
import cameralImg from "./assets/products/Kamera-Sistemi.png";
import backCameraImg from "./assets/products/Geri-Gorus-Kamera.png";
import parkSensorImg from "./assets/products/Kablosuz-Park-Sensoru.png";
import reparter from "./assets/products/reparter.png";

const productsData = [
  {
    title: "Remote (Kumanda)",
    description:
      "Uzaktan bilgilendirme için sensörlerin poziyonlarına göre programlama yapar.",
    image: remoteImg,
    specifications: [
      "Kolay kodlama",
      "Lastik içinde jant genişliği üzerinde konumlama",
      "Tüm jant tipleri ile uyumlu",
      "Su geçirmez",
      "Sensöre bağlanan özel subap",
      "Ani hava kaybı bilgisi",
      "Ayrı ayrı kodlanabilme özelliği",
    ],
    name: "remote",
  },
  {
    title: "Dahili Sensör",
    description:
      "Takıldığı lastiğin basınç ve sıcaklıklarını ölçerek kablosuz bir şekilde monitöre iletir.",

    specifications: [
      "Kolay kodlama",
      "Lastik içinde jant genişliği üzerinde konumlama",
      "Tüm jant tipleri ile uyumlu",
      "Su geçirmez",
      "Sensöre bağlanan özel subap",
      "Ani hava kaybı bilgisi",
      "Ayrı ayrı kodlanabilme özelliği",
    ],
    image: internalImg,
    name: "internal",
  },
  {
    title: "Harici Sensör",
    description:
      "Takıldığı lastiğin basınç ve sıcaklıklarını ölçerek kablosuz bir şekilde monitöre iletir.",
    specifications: [
      "Güvenilir ve kolay kodlanabilir kapak sensörler",
      "Su geçirmez özellik",
      "Değişebilen pil özelliği.",
      "Ani hava kaybı alarmı",
      "Ayrı ayrı kodlanabilme özelliği.",
      "Çalınmaya karşı korumalı dizayn",
      "Lastik değişince tekrar kodlama gerektirmez",
    ],
    image: sensorImg,
    name: "sensor",
  },
  {
    title: "Monitor",
    description:
      "Aracınızın lastik pozisyonuna göre basınç ve sıcaklık bilgilerinizi anlık olarak görüntülemenizi sağlar.",
    specifications: [
      "Güvenilir ve kolay kurulum",
      "Geniş LCD ekran",
      "Şarj edilebilir lityum pil",
      "Otomatik arka ışıklandırma",
      "Ayarlanabilir düşük/yüksek hava basınç uyarı baremi",
      "Yüksek sıcaklık uyarı baremi.",
      "Duyusal ve görsel alarm",
      "Seçilebilir basınç birimleri",
      "22 lastik görüntüleme",
      "Monitor ve sensörler arası uzak mesafe iletişim gücü",
      "Araç aksına göre ayarlanabilir basınç baremleri",
    ],
    image: monitorImg,
    name: "monitor",
  },
  {
    title: "Sinyal Güçlendirici",
    description:
      "Uzaktan bilgilendirme sistemi için kullanılır. Sinyal gönderim stabilitesi sağlar. ",
    specifications: [
      "Sinyal gönderim stabilitesi sağlar.",
      "Sensör bilgilerini ve düşük –yüksek hava basıncı ve yüksek sıcaklık baremlerini kaydeder.",
      "Treyler değişim durumunda tek tuş ile ,bağlanan çekici monitörüne bilgi aktarımı",
      "Sensörler ve monitör arasında bilgi transferi sağlar.",
      "Aktarılan sensör kimlik bilgilerini ve düşük- yüksek hava basınç ve yüksek sıcaklık baremlerini hafızada tutar.",
    ],
    image: reparter,
    name: "reparter",
  },
  // dummy datas
];

export default productsData;
