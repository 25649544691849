import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Mousewheel } from "swiper/modules";
import "swiper/scss";

import "../styles/ReviewSwiper.scss"; // Update your CSS if needed

import ReviewCard from "./ReviewCard";

import rightWings from "../assets/Lines-right.png";
import leftWings from "../assets/Lines-Left.png";

const reviews = [
  {
    name: "BÜLENT ŞAM",
    position: "ÖNDER CANBEK İNŞ. NAK. AK. SAN. VE TİC. LTD. ŞTİ.",
    text: "Üç yıldır kullandığımız Airguard sayesinde yolda seyir esnasında araçlarımızda yaşanan anlık sıcaklık problemlerinden dolayı yaşanabilecek bir çok problemi engelledik.",
  },

  {
    name: "ZAFER YAPAROĞLU",
    position: "YAPAROĞLU TAŞIMACILIK",
    text: "Airguard sayesinde bizim için lastikler artık  problem olmaktan çıktı.",
  },
  {
    name: "SAVAŞ ÖZTÜRK",
    position: "ÖZTRÜK KIRŞEHİR NAKLİYAT",
    text: "Sistemi bir yılı aşkın süredir tüm araçlarımızda kullanmaktayız, bu süre zarfında lastikler ile ilgili yaşanılan problemlerimizi geçtiğimiz yılların ortalamasına göre %90 oranında azaltmayı başardık.",
  },

  // Add more reviews as needed
];

const ReviewSwiper = () => {
  return (
    <div className="review_swiper">
      <div className="review_swiper_title">
        <img src={leftWings} alt="text wings" />
        <h2>Memnun Müşterilerimiz</h2>
        <img src={rightWings} alt="text wings" />
      </div>
      <h1 className="review_swiper_heading">AIRGUARD İÇİN NE DEDİLER?</h1>
      <Swiper
        className="review_swiper_container"
        modules={[Pagination, Autoplay]} // Add Mousewheel for scroll interaction
        spaceBetween={30}
        loop={true} // Infinite loop
        slidesPerView={2}
        pagination={{ clickable: true }}
        autoplay={{ delay: 4000, disableOnInteraction: false }} // Auto-slide
        // grabCursor={true} // Change cursor to grab for drag functionality
        // mousewheel={true} // Enable mousewheel to scroll through slides
        breakpoints={{
          779: { slidesPerView: 2 }, // For tablets
          480: { slidesPerView: 1 }, // For tablets
          280: { slidesPerView: 1 }, // For mobile
        }}
      >
        {reviews.map((review, index) => (
          <SwiperSlide className="review_swiper_slide" key={index}>
            <ReviewCard
              review={review}
              className={index % 2 === 0 ? "even_background" : "odd_background"}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ReviewSwiper;
